import { observer } from "mobx-react-lite";
import { CheckboxItem, CheckboxList, Container } from "./styled";
import { useStore } from "../../lib/useStore";
import { Tags } from "../../types";
import { TAGS_TRANSLATE } from "../../../pages/Companies/components/CompanyInfo";

const Filters = () => {
  const {
    companies: { setFilterQueries, filterQueries },
  } = useStore();

  const handleSetTags = (tag: Tags) => {
    let newTag = "";
    if (tag === filterQueries.tags) {
      newTag = "";
    } else {
      newTag = tag;
    }
    setFilterQueries({ key: "tags", value: newTag });
  };

  return (
    <Container>
      <CheckboxList>
        {Object.values(Tags).map((el) => (
          <CheckboxItem
            key={el}
            selected={filterQueries.tags === el}
            onClick={() => handleSetTags(el)}
          >
            {TAGS_TRANSLATE[el]}
          </CheckboxItem>
        ))}
      </CheckboxList>
    </Container>
  );
};

export default observer(Filters);
