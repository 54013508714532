// import {useLocation} from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "shared/lib/useStore";
import Logo from "shared/ui/Logo";
import { ReactComponent as DayIcon } from "shared/ui/icons/day.svg";
import { ReactComponent as NightIcon } from "shared/ui/icons/night.svg";

import {
  MenuHeader,
  MenuItem,
  MenuList,
  MenuContainer,
  MenuTitle,
  ThemeToggle,
} from "./styled";
import { ROUTES } from "../../constants/routes";

const Menu = () => {
  const {
    theme: { toggleTheme, isDarkTheme },
  } = useStore();

  // const { pathname } = useLocation();

  return (
    <MenuContainer>
      <MenuHeader>
        <Logo />
      </MenuHeader>
      <MenuList>
        <MenuItem selected to={ROUTES.COMPANIES}>
          <MenuTitle>Компании</MenuTitle>
        </MenuItem>
      </MenuList>
      <ThemeToggle onClick={toggleTheme}>
        {isDarkTheme ? <DayIcon /> : <NightIcon />}
      </ThemeToggle>
    </MenuContainer>
  );
};

export default observer(Menu);
