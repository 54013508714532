import { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ROUTES } from "shared/constants/routes";
import Spinner from "shared/ui/Spinner";
import Layout from "../shared/ui/Layout";

const Companies = lazy(() => import("pages/Companies"));
// const Board = lazy(() => import("pages/Board"));

const PrivateRoutes = () => (
  <Suspense fallback={<Spinner />}>
    <Layout>
      <Routes>
        <Route path={ROUTES.COMPANIES} element={<Companies />} />
        <Route
          path={`${ROUTES.COMPANIES}/:companyId`}
          element={<Companies />}
        />
        {/* <Route path={ROUTES.BOARD} element={<Board />} /> */}
        <Route path="*" element={<Navigate to={ROUTES.COMPANIES} />} />
      </Routes>
    </Layout>
  </Suspense>
);

export default PrivateRoutes;
