import styled from "styled-components";

export const Container = styled.div`
  margin: 0 0 0 40px;
`;

export const CheckboxList = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxItem = styled.button<{ selected: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.mainFont};
  background: ${({ theme, selected }) =>
    selected ? theme.mainBlue : theme.cardBg};
  border-radius: 6px;
  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.mainBlue : theme.mainBorder)};
  margin: 0 10px 0 0;
  padding: 4px 10px;
  font-size: 14px;
  cursor: pointer;

  @media (max-width: 1200px) {
    font-size: 12px;
    padding: 4px 6px;
  }
`;
