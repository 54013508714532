import styled from "styled-components";
import { HEADER_HEIGHT } from "../../shared/constants/globalStyles";

export const CompaniesList = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  margin: 0 auto;
  background: url("/pattern.png") ${({ theme }) => theme.darkBg} repeat;
`;
