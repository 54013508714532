import styled from "styled-components";
import { HEADER_HEIGHT } from "../../../../shared/constants/globalStyles";

export const Container = styled.div`
  max-height: calc(100vh - ${HEADER_HEIGHT}px - 40px);
  width: 100%;
  color: ${({ theme }) => theme.mainFont};
  padding: 40px 40px 60px;

  @media (max-width: 1200px) {
    overflow: auto;
    padding: 10px 15px;
    max-height: 100%;
    margin: 0 0 10px 0;
  }
`;

export const Row = styled.div<{ selected?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme, selected }) => (selected ? "#6471b7" : theme.mainBg)};
  padding: 0 14px;
  border-bottom: 1px solid ${({ theme }) => theme.mainBorder};
  font-size: 13px;
  min-width: 1000px;

  a {
    ${({ selected }) => selected && "color: #fff"};
    text-decoration: none;
  }

  &:hover {
    background: ${({ theme, selected }) =>
      // eslint-disable-next-line no-nested-ternary
      selected ? "#6471b7" : theme.dark ? theme.cardBg : theme.listBg};
  }
`;
export const Cell = styled.div<{ selected?: boolean }>`
  height: 100%;
  width: calc(100% / 5);
  padding: 14px 0;

  &:first-child {
    width: 20%;
    a {
      display: inline-flex;
      align-items: center;
      padding: 4px 0;
      font-size: 14px;

      svg {
        color: #fbb636;
        margin: 0 8px 0 0;
        font-size: 16px;
      }
    }
  }

  &:nth-child(2) {
    width: 20%;
  }

  &:nth-child(6) {
    width: 5%;
  }

  &:last-child {
    width: 5%;
  }
`;
export const TableBody = styled.div`
  position: relative;
  max-height: 100%;
  overflow: auto;

  @media (max-width: 1200px) {
    overflow: initial;
  }
`;
export const HeaderRow = styled(Row)`
  border-radius: 6px 6px 0 0;
  font-weight: 700;
  font-size: 13px;
  background: ${({ theme }) => (theme.dark ? theme.cardBg : theme.listBg)};

  &:hover {
    background: ${({ theme }) => theme.cardBg};
  }
`;

export const SortButton = styled.button<{ selected: boolean }>`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${({ theme, selected }) =>
    selected ? theme.mainBlue : theme.mainFont};
  font: inherit;
  padding: 0;
  margin: 0;

  svg {
    margin: 0 0 0 4px;
  }
`;
