import { observer } from "mobx-react-lite";
import { Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CaretDownOutlined, DollarOutlined } from "@ant-design/icons";
import {
  Container,
  Row,
  Cell,
  HeaderRow,
  SortButton,
  TableBody,
} from "./styled";
import { useStore } from "../../../../shared/lib/useStore";
import { createdDate } from "../../index";
import { ROUTES } from "../../../../shared/constants/routes";
import { TAGS_COLORS, TAGS_TRANSLATE } from "../CompanyInfo";
import Spinner from "../../../../shared/ui/Spinner";

const headerTitles = [
  "Компания",
  "Тэги",
  "Зарегистрирован",
  "Последний визит",
  "Кандидаты",
  "Задачи",
];

export interface TableProps {
  selectedRow?: string;
}

const Table = ({ selectedRow }: TableProps) => {
  const {
    companies: {
      companiesList,
      setFilterQueries,
      filterQueries,
      isFetchingCompanies,
    },
  } = useStore();

  const handleSortByLastSeen = () => {
    const value = filterQueries.sortBy === "lastSeen" ? "" : "lastSeen";
    setFilterQueries({ key: "sortBy", value });
  };

  return (
    <Container>
      <HeaderRow>
        {headerTitles.map((el) => (
          <Cell key={el}>
            {el === headerTitles[3] ? (
              // eslint-disable-next-line react/button-has-type
              <SortButton
                selected={filterQueries.sortBy === "lastSeen"}
                onClick={handleSortByLastSeen}
              >
                {el}
                <CaretDownOutlined />
              </SortButton>
            ) : (
              el
            )}
          </Cell>
        ))}
      </HeaderRow>
      <TableBody>
        {isFetchingCompanies && <Spinner />}
        {companiesList.map(
          ({
            companyInfo: { name, plan, usersList, registrationAt, lastSeen },
            companyId,
            candidatesCount,
            tasksCount,
            tags,
          }) => (
            <Row selected={selectedRow === companyId} key={companyId}>
              <Cell>
                <Link to={`${ROUTES.COMPANIES}/${companyId}`}>
                  {plan === "pro" && <DollarOutlined />}
                  {name === "Моя новая компания"
                    ? usersList.find((el) => el.companyOwnerId === companyId)
                        ?.userEmail
                    : name}
                </Link>
              </Cell>
              <Cell>
                {tags.map((el) => (
                  <Tag
                    key={el}
                    style={{ fontSize: "10px" }}
                    color={TAGS_COLORS[el]}
                  >
                    {TAGS_TRANSLATE[el]}
                  </Tag>
                ))}
              </Cell>
              <Cell>{createdDate(registrationAt)}</Cell>
              <Cell selected={filterQueries.sortBy === "lastSeen"}>
                {lastSeen ? createdDate(lastSeen, true) : "Не заходил"}
              </Cell>
              <Cell>{candidatesCount}</Cell>
              <Cell>{tasksCount}</Cell>
            </Row>
          )
        )}
      </TableBody>
    </Container>
  );
};

export default observer(Table);
