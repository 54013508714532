import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { CSSTransition } from "react-transition-group";
import { CompaniesList } from "./styled";
import CompanyInfo from "./components/CompanyInfo";
import { useStore } from "../../shared/lib/useStore";
import Table from "./components/Table";

export const formatDate = (date: number) =>
  new Date(+date).toLocaleString("ru", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

export const createdDate = (createdAt: number, withTime?: boolean) => {
  const dateNow = new Date();
  const dateCreated = new Date(createdAt);

  // Определяем дату сегодня и вчера для сравнения
  const today = new Date(
    dateNow.getFullYear(),
    dateNow.getMonth(),
    dateNow.getDate()
  );
  const yesterday = new Date(
    dateNow.getFullYear(),
    dateNow.getMonth(),
    dateNow.getDate() - 1
  );

  const time = dateCreated.toLocaleString("ru", {
    hour: "numeric",
    minute: "numeric",
  });

  // Сравниваем даты в формате год-месяц-день
  if (dateCreated >= today) {
    // Событие сегодня
    return `сегодня ${withTime ? `в ${time}` : ""}`;
  }
  if (dateCreated >= yesterday) {
    // Событие вчера
    return `вчера ${withTime ? `в ${time}` : ""}`;
  }
  // Возвращаем полную дату, если событие было ранее вчера
  return dateCreated.toLocaleString("ru", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const Companies = () => {
  const { companies } = useStore();

  const { companyId } = useParams<{ companyId: string }>();

  useEffect(() => {
    companies.fetchCompaniesList();
  }, []);

  return (
    <CompaniesList>
      <Table selectedRow={companyId} />
      <CSSTransition
        in={!!companyId}
        timeout={200}
        classNames="companyInfo"
        unmountOnExit
      >
        <CompanyInfo companyId={companyId || ""} />
      </CSSTransition>
    </CompaniesList>
  );
};

export default observer(Companies);
