import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react-lite";
import { Tag } from "antd";
import {
  DollarOutlined,
  StarOutlined,
  CopyOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import {
  AddBtn,
  AddCommentField,
  AddCommentWrap,
  CloseBtn,
  CommentItem,
  CommentItemDate,
  CommentsList,
  CompanyLogo,
  Container,
  Content,
  CopyWrap,
  Heading,
  MetaInfo,
  Overlay,
  TagsList,
  UserItem,
  UserLastSeen,
  UserList,
} from "./styled";
import { useStore } from "../../../../shared/lib/useStore";
import { createdDate, formatDate } from "../../index";
import { Tags } from "../../../../shared/types";
import { ROUTES } from "../../../../shared/constants/routes";

export interface CompanyInfoProps {
  companyId: string;
}

export const TAGS_COLORS = {
  [Tags.problem]: "#f34c44",
  // [Tags.problem]: "#7487eb",
  [Tags.needContact]: "#fb8936",
  [Tags.waitingAnswer]: "#62c778",
  // [Stages.offer]: '#62c778',
};

export const TAGS_TRANSLATE = {
  [Tags.problem]: "Проблема",
  [Tags.needContact]: "Нужно связаться",
  [Tags.waitingAnswer]: "Жду ответ",
};

const CompanyInfo = ({ companyId }: CompanyInfoProps) => {
  const {
    notification: { pushToNotificationsList },
    companies: {
      fetchCompanyById,
      updateCompanyInfo,
      isFetchingCompanies,
      selectedCompany: {
        companyInfo: {
          name,
          usersList,
          plan,
          planExpires,
          brandLogo,
          registrationAt,
          lastSeen,
        },
        comments,
        tags,
        candidatesCount,
        tasksCount,
      },
    },
  } = useStore();
  const [field, setField] = useState("");
  const navigate = useNavigate();

  const handleSetField = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = ev.target;
    setField(value);
  };

  const sendMessage = () => {
    updateCompanyInfo({
      companyId,
      comments: [
        ...comments,
        { createdAt: Date.now(), value: field, commentId: uuidv4() },
      ],
      tags: [...tags],
    });
    setField("");
  };

  const setTags = (tag: Tags) => {
    let newTags = [...tags];
    let notifyMessage = "";
    if (newTags.includes(tag)) {
      newTags = newTags.filter((el) => el !== tag);
      notifyMessage = `Удалён тэг ${TAGS_TRANSLATE[tag]}`;
    } else {
      newTags.push(tag);
      notifyMessage = `Добавлен тэг ${TAGS_TRANSLATE[tag]}`;
    }
    updateCompanyInfo({
      companyId,
      comments: notifyMessage
        ? [
            ...comments,
            {
              createdAt: Date.now(),
              value: notifyMessage,
              commentId: uuidv4(),
            },
          ]
        : [...comments],
      tags: newTags,
    });
  };

  const copyLinkHandler = (text: string): void => {
    pushToNotificationsList({ description: `Скопировано ${text}` });
    navigator.clipboard.writeText(text);
  };
  const handleCloseInfo = () => navigate(ROUTES.COMPANIES);

  useEffect(() => {
    if (companyId) {
      fetchCompanyById(companyId);
    }
  }, [companyId, isFetchingCompanies]);

  return (
    <Container>
      <Overlay onClick={handleCloseInfo} />
      <Content>
        <CloseBtn onClick={handleCloseInfo}>
          <CloseOutlined />
        </CloseBtn>
        <CompanyLogo src={brandLogo} />
        <Heading>
          {plan === "pro" && <DollarOutlined />}
          {name}
        </Heading>
        {plan === "pro" && (
          <h5>
            Тариф {plan} до {formatDate(planExpires)}
          </h5>
        )}
        <TagsList>
          {Object.values(Tags).map((el) => (
            <Tag
              key={el}
              style={{ cursor: "pointer" }}
              onClick={() => setTags(el)}
              color={tags.includes(el) ? TAGS_COLORS[el] : undefined}
            >
              {TAGS_TRANSLATE[el]}
            </Tag>
          ))}
        </TagsList>
        <MetaInfo>
          Кандидатов: {candidatesCount}, Задач: {tasksCount}
        </MetaInfo>
        <MetaInfo>
          Последний визит{" "}
          {lastSeen ? createdDate(lastSeen, true) : "Не заходил"}
        </MetaInfo>
        <MetaInfo>Зарегистрирован: {createdDate(registrationAt)}</MetaInfo>
        <UserList>
          {[...usersList]
            .sort((a, b) => b.lastSeen - a.lastSeen)
            .map(
              ({
                userEmail,
                companyOwnerId,
                lastSeen: userLastSeen,
                firstName,
              }) => (
                <UserItem owner={companyOwnerId === companyId} key={userEmail}>
                  {companyOwnerId === companyId && <StarOutlined />}
                  {firstName} {userEmail}
                  <CopyWrap>
                    <CopyOutlined onClick={() => copyLinkHandler(userEmail)} />
                  </CopyWrap>
                  <UserLastSeen>
                    - Заходил {createdDate(userLastSeen, true)}
                  </UserLastSeen>
                </UserItem>
              )
            )}
        </UserList>
        <CommentsList>
          <h4>Комментарии</h4>
          {comments.map(({ value, createdAt, commentId }) => (
            <CommentItem key={commentId}>
              {value}
              <CommentItemDate>{formatDate(createdAt)}</CommentItemDate>
            </CommentItem>
          ))}
        </CommentsList>
        <AddCommentWrap>
          <AddCommentField
            value={field}
            placeholder="Добавить комментарий"
            onChange={handleSetField}
          />
          <AddBtn disabled={!field} onClick={sendMessage}>
            Добавить
          </AddBtn>
        </AddCommentWrap>
      </Content>
    </Container>
  );
};

export default observer(CompanyInfo);
