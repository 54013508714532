import { Candidate, Company, Plans, Vacancy, VacancyInfo } from "shared/types";

export const initialCompany = (): Company => ({
  companyInfo: {
    _id: "",
    avatar: "",
    brandLogo: "",
    name: "",
    ownerId: "",
    plan: Plans.FREE,
    planExpires: 0,
    usersList: [],
    registrationAt: 0,
    lastSeen: 0,
  },
  companyId: "",
  comments: [],
  tags: [],
  candidatesCount: 0,
  tasksCount: 0,
});

export const initialCandidate = (vacancyId: string): Candidate => ({
  _id: "",
  userId: "",
  vacancyId,
  name: "",
  grade: "",
  comment: "",
  contact: "",
  link: "",
  tags: "",
  createdAt: 0,
});

export const initialVacancy = (): Vacancy => ({
  _id: "",
  userId: "",
  name: "",
  link: "",
  columns: [],
  createdAt: 0,
});

export const initialVacancyInfo = (): VacancyInfo => ({
  ...initialVacancy(),
  columns: [],
});

// export const demoVacancies: Vacancy[] = [
//   {
//     _id: "vacancy1",
//     name: "Senior React-Developer",
//     company: "Сбербанк",
//     candidates: [
//       {
//         title: "Новые",
//         type: Stages.new,
//         list: [
//           {
//             _id: "1",
//             vacancyId: "vacancy1",
//             name: "Rick Sanchez",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/1.jpeg",
//             comment: "",
//             tags: "Male,Alive ,",
//             contact: "Citadel of Ricks",
//             link: "https://rickandmortyapi.com/api/location/3",
//           },
//           {
//             _id: "2",
//             vacancyId: "vacancy1",
//             name: "Morty Smith",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/2.jpeg",
//             comment: "",
//             tags: "Male,Alive ,",
//             contact: "Citadel of Ricks",
//             link: "https://rickandmortyapi.com/api/location/3",
//           },
//           {
//             _id: "3",
//             vacancyId: "vacancy1",
//             name: "Summer Smith",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/3.jpeg",
//             comment: "",
//             tags: "Female,Alive ,",
//             contact: "Earth (Replacement Dimension)",
//             link: "https://rickandmortyapi.com/api/location/20",
//           },
//           {
//             _id: "4",
//             vacancyId: "vacancy1",
//             name: "Beth Smith",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/4.jpeg",
//             comment: "",
//             tags: "Female,Alive ,",
//             contact: "Earth (Replacement Dimension)",
//             link: "https://rickandmortyapi.com/api/location/20",
//           },
//           {
//             _id: "5",
//             vacancyId: "vacancy1",
//             name: "Jerry Smith",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/5.jpeg",
//             comment: "",
//             tags: "Male,Alive ,",
//             contact: "Earth (Replacement Dimension)",
//             link: "https://rickandmortyapi.com/api/location/20",
//           },
//         ],
//       },
//       {
//         title: "В работе",
//         type: Stages.inProgress,
//         list: [
//           {
//             _id: "7",
//             vacancyId: "vacancy1",
//             name: "Abradolf Lincler",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/7.jpeg",
//             comment: "",
//             tags: "Male,unknown ,Genetic experiment",
//             contact: "Testicle Monster Dimension",
//             link: "https://rickandmortyapi.com/api/location/21",
//           },
//           {
//             _id: "8",
//             vacancyId: "vacancy1",
//             name: "Adjudicator Rick",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/8.jpeg",
//             comment: "",
//             tags: "Male,Dead ,",
//             contact: "Citadel of Ricks",
//             link: "https://rickandmortyapi.com/api/location/3",
//           },
//           {
//             _id: "9",
//             vacancyId: "vacancy1",
//             name: "Agency Director",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/9.jpeg",
//             comment: "",
//             tags: "Male,Dead ,",
//             contact: "Earth (Replacement Dimension)",
//             link: "https://rickandmortyapi.com/api/location/20",
//           },
//           {
//             _id: "10",
//             vacancyId: "vacancy1",
//             name: "Alan Rails",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/10.jpeg",
//             comment: "",
//             tags: "Male,Dead ,Superhuman (Ghost trains summoner)",
//             contact: "Worldender's lair",
//             link: "https://rickandmortyapi.com/api/location/4",
//           },
//           {
//             _id: "11",
//             vacancyId: "vacancy1",
//             name: "Albert Einstein",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/11.jpeg",
//             comment: "",
//             tags: "Male,Dead ,",
//             contact: "Earth (Replacement Dimension)",
//             link: "https://rickandmortyapi.com/api/location/20",
//           },
//         ],
//       },
//       {
//         title: "Прошли интервью",
//         type: Stages.interviewed,
//         list: [
//           {
//             _id: "13",
//             vacancyId: "vacancy1",
//             name: "Alien Googah",
//             grade: "Alien",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/13.jpeg",
//             comment: "",
//             tags: "unknown,unknown ,",
//             contact: "Earth (Replacement Dimension)",
//             link: "https://rickandmortyapi.com/api/location/20",
//           },
//           {
//             _id: "14",
//             vacancyId: "vacancy1",
//             name: "Alien Morty",
//             grade: "Alien",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/14.jpeg",
//             comment: "",
//             tags: "Male,unknown ,",
//             contact: "Citadel of Ricks",
//             link: "https://rickandmortyapi.com/api/location/3",
//           },
//           {
//             _id: "15",
//             vacancyId: "vacancy1",
//             name: "Alien Rick",
//             grade: "Alien",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/15.jpeg",
//             comment: "",
//             tags: "Male,unknown ,",
//             contact: "Citadel of Ricks",
//             link: "https://rickandmortyapi.com/api/location/3",
//           },
//           {
//             _id: "16",
//             vacancyId: "vacancy1",
//             name: "Amish Cyborg",
//             grade: "Alien",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/16.jpeg",
//             comment: "",
//             tags: "Male,Dead ,Parasite",
//             contact: "Earth (Replacement Dimension)",
//             link: "https://rickandmortyapi.com/api/location/20",
//           },
//           {
//             _id: "17",
//             vacancyId: "vacancy1",
//             name: "Annie",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/17.jpeg",
//             comment: "",
//             tags: "Female,Alive ,",
//             contact: "Anatomy Park",
//             link: "https://rickandmortyapi.com/api/location/5",
//           },
//         ],
//       },
//       {
//         title: "Оффер",
//         type: Stages.offer,
//         list: [
//           {
//             _id: "19",
//             vacancyId: "vacancy1",
//             name: "Antenna Rick",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/19.jpeg",
//             comment: "",
//             tags: "Male,unknown ,Human with antennae",
//             contact: "unknown",
//             link: "",
//           },
//           {
//             _id: "20",
//             vacancyId: "vacancy1",
//             name: "Ants in my Eyes Johnson",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/20.jpeg",
//             comment: "",
//             tags: "Male,unknown ,Human with ants in his eyes",
//             contact: "Interdimensional Cable",
//             link: "https://rickandmortyapi.com/api/location/6",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     _id: "vacancy2",
//     name: "Фронтенд-разработчик Vue",
//     company: "Яндекс",
//     candidates: [
//       {
//         title: "Новые",
//         type: Stages.new,
//         list: [
//           {
//             _id: "22",
//             vacancyId: "vacancy2",
//             name: "Rick Sanchez",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/1.jpeg",
//             comment: "",
//             tags: "Male,Alive ,",
//             contact: "Citadel of Ricks",
//             link: "https://rickandmortyapi.com/api/location/3",
//           },
//           {
//             _id: "33",
//             vacancyId: "vacancy2",
//             name: "Morty Smith",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/2.jpeg",
//             comment: "",
//             tags: "Male,Alive ,",
//             contact: "Citadel of Ricks",
//             link: "https://rickandmortyapi.com/api/location/3",
//           },
//         ],
//       },
//       {
//         title: "В работе",
//         type: Stages.inProgress,
//         list: [
//           {
//             _id: "77",
//             vacancyId: "vacancy2",
//             name: "Abradolf Lincler",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/7.jpeg",
//             comment: "",
//             tags: "Male,unknown ,Genetic experiment",
//             contact: "Testicle Monster Dimension",
//             link: "https://rickandmortyapi.com/api/location/21",
//           },
//           {
//             _id: "88",
//             vacancyId: "vacancy2",
//             name: "Adjudicator Rick",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/8.jpeg",
//             comment: "",
//             tags: "Male,Dead ,",
//             contact: "Citadel of Ricks",
//             link: "https://rickandmortyapi.com/api/location/3",
//           },
//         ],
//       },
//       {
//         title: "Прошли интервью",
//         type: Stages.interviewed,
//         list: [
//           {
//             _id: "133",
//             vacancyId: "vacancy2",
//             name: "Alien Googah",
//             grade: "Alien",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/13.jpeg",
//             comment: "",
//             tags: "unknown,unknown ,",
//             contact: "Earth (Replacement Dimension)",
//             link: "https://rickandmortyapi.com/api/location/20",
//           },
//         ],
//       },
//       {
//         title: "Оффер",
//         type: Stages.offer,
//         list: [
//           {
//             _id: "199",
//             vacancyId: "vacancy2",
//             name: "Antenna Rick",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/19.jpeg",
//             comment: "",
//             tags: "Male,unknown ,Human with antennae",
//             contact: "unknown",
//             link: "",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     _id: "vacancy3",
//     name: "Middle React",
//     company: "Ланит",
//     columns: [
//       {
//         title: "Новые",
//         type: Stages.new,
//         list: [
//           {
//             _id: "32432",
//             vacancyId: "vacancy3",
//             name: "Morty Smith",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/2.jpeg",
//             comment: "",
//             tags: "Male,Alive ,",
//             contact: "Citadel of Ricks",
//             link: "https://rickandmortyapi.com/api/location/3",
//           },
//         ],
//       },
//       {
//         title: "В работе",
//         type: Stages.inProgress,
//         list: [
//           {
//             _id: "7655",
//             vacancyId: "vacancy3",
//             name: "Abradolf Lincler",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/7.jpeg",
//             comment: "",
//             tags: "Male,unknown ,Genetic experiment",
//             contact: "Testicle Monster Dimension",
//             link: "https://rickandmortyapi.com/api/location/21",
//           },
//           {
//             _id: "5465462",
//             vacancyId: "vacancy3",
//             name: "Adjudicator Rick",
//             grade: "Human",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/8.jpeg",
//             comment: "",
//             tags: "Male,Dead ,",
//             contact: "Citadel of Ricks",
//             link: "https://rickandmortyapi.com/api/location/3",
//           },
//         ],
//       },
//       {
//         title: "Прошли интервью",
//         type: Stages.interviewed,
//         list: [
//           {
//             _id: "132233",
//             vacancyId: "vacancy3",
//             name: "Alien Googah",
//             grade: "Alien",
//             avatar: "https://rickandmortyapi.com/api/character/avatar/13.jpeg",
//             comment: "",
//             tags: "unknown,unknown ,",
//             contact: "Earth (Replacement Dimension)",
//             link: "https://rickandmortyapi.com/api/location/20",
//           },
//         ],
//       },
//       {
//         title: "Оффер",
//         type: Stages.offer,
//         list: [],
//       },
//     ],
//   },
// ];
