import styled from "styled-components";

const contentWidth = 700;

export const Overlay = styled.div`
  height: 100%;
  flex-grow: 1;
`;

export const Content = styled.div`
  position: relative;
  width: ${contentWidth}px;
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.mainBorder};
  color: ${({ theme }) => theme.mainFont};
  padding: 30px 20px;
  background: ${({ theme }) => theme.darkBg};
  overflow: auto;
  transform: translateX(100%);

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);

  &.companyInfo-enter-active {
    opacity: 1;
    transition: 0.2s;
    ${Content} {
      transform: translateX(0);
      transition: 0.2s;
    }
  }

  &.companyInfo-enter-done {
    opacity: 1;
    transition: 0.2s;
    ${Content} {
      transform: translateX(0);
      transition: 0.2s;
    }
  }

  &.companyInfo-exit {
    opacity: 0;
    transition: 0.2s;
    ${Content} {
      transform: translateX(100%);
      transition: 0.2s;
    }
  }
`;

export const CompanyLogo = styled.img`
  display: block;
  margin: 0 0 20px 0;
  max-height: 50px;
`;

export const Heading = styled.span`
  display: block;
  font-size: 20px;
  padding: 0 40px 0 0;

  svg {
    color: #fbb636;
    margin: 0 8px 0 0;
  }
`;

export const TagsList = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

export const UserList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
`;

export const MetaInfo = styled.span`
  display: block;
  margin: 0 0 10px 0;
  font-size: 14px;
`;

export const UserItem = styled.span<{ owner: boolean }>`
  display: flex;
  align-items: center;
  ${({ owner }) => owner && "order: -1;"};
  margin: 0 0 10px 0;
  svg {
    margin: 0 4px 0 0;
  }

  @media (max-width: 1200px) {
    display: block;
  }
`;

export const CopyWrap = styled.div`
  margin: 0 8px 0 10px;
  font-size: 13px;
  svg {
    font-size: 16px;
    margin: 0;
  }
`;

export const UserLastSeen = styled.span`
  font-size: 11px;
  margin: 0 0 0 10px;
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 30px;
  right: 20px;
  background: transparent;
  font-size: 20px;
  outline: none;
  border: none;
  color: inherit;
  cursor: pointer;
`;

export const AddCommentWrap = styled.div`
  border-top: 1px solid ${({ theme }) => theme.mainBorder};
  padding: 20px 0 0 0;
`;

export const CommentsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid ${({ theme }) => theme.mainBorder};
  padding: 0 0 0 0;
  margin: 20px 0 0 0;
`;

export const CommentItem = styled.span`
  position: relative;
  max-width: 100%;
  word-wrap: break-word;
  padding: 4px 12px 2px;
  border-radius: 6px;
  background: ${({ theme }) => (theme.dark ? "#29292c" : theme.listBg)};
  margin: 0 0 10px 0;
  font-size: 14px;
`;

export const CommentItemDate = styled.span`
  display: block;
  font-size: 10px;
  margin: 2px 0 0 0;
  font-style: italic;
`;

export const AddCommentField = styled.textarea`
  width: 100%;
  resize: none;
  margin: 0 0 14px 0;
  padding: 15px 20px;
  background: ${({ theme }) => (theme.dark ? "#35353a" : theme.listBg)};
  border-radius: 6px;
  color: inherit;
  outline: none;
  transition: 0.1s;
  border: none;

  &::placeholder {
    font-size: 14px;
  }

  &:focus {
    border-color: ${({ theme }) => theme.mainBlue};
    box-shadow: "rgba(116, 135, 235, 0.5)" 0 4px 12px 0;
  }

  height: 120px;
`;

export const AddBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 40px;
  color: #fff;
  font-weight: 600;
  border-radius: 6px;
  background: ${({ theme }) => theme.mainBlue};
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;
