import { ReactNode } from "react";
import Menu from "../Menu";

import { Row, Column } from "./styled";
import Header from "../Header";

export interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => (
  <Row>
    <Menu />
    <Column>
      <Header />
      {children}
    </Column>
  </Row>
);

export default Layout;
