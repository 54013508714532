import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "shared/lib/useStore";
import Search from "shared/ui/Search";
import { debounce } from "shared/lib/debounce";

import { HeaderContainer, Heading } from "./styled";
import Filters from "../Filters";

const Header = () => {
  const {
    companies: { setFilterQueries },
  } = useStore();

  const setSearchQuery = (value: string) =>
    setFilterQueries({ key: "search", value });

  const debouncedSetSearchQuery = useCallback(
    debounce(setSearchQuery, 500),
    []
  );

  return (
    <HeaderContainer>
      <Heading>Компании</Heading>
      <Search onSearch={debouncedSetSearchQuery} />
      <Filters />
    </HeaderContainer>
  );
};

export default observer(Header);
