import styled from "styled-components";
import { HEADER_HEIGHT } from "shared/constants/globalStyles";

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.mainBorder};
  padding: 0 30px;
  color: ${({ theme }) => theme.mainFont};
  background: ${({ theme }) => theme.mainBg};

  @media (max-width: 767px) {
    padding: 6px 10px;
  }
`;

export const Heading = styled.span`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 600;

  @media (max-width: 1300px) {
    font-size: 18px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;
